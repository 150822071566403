<template>
  <b-container fluid>
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">
            Gutschrift: {{ credit.credit_number }}
          </h3>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-md-4" style="display: flex; flex-direction: column;">
              <div class="card card-outline card-primary" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                <div class="card-header">
                  <h3 class="card-title">Rechnungsanschrift</h3>
                </div>

                <div class="card-body">
                  <template v-if="credit.company != null">
                    {{ credit.company }}<br>
                    <template v-if="credit.department != null">
                      {{ credit.department }}<br>
                    </template>
                  </template>
                  {{ credit.first_name }} {{ credit.last_name }}<br>
                  {{ credit.street }} {{ credit.house_number }}<br>
                  {{ credit.zipcode }} {{ credit.city }}<br>
                </div>
              </div>
            </div>

            <div class="col-md-4" style="display: flex; flex-direction: column;">
              <div class="card card-outline card-primary" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                <div class="card-header">
                  <h3 class="card-title">Status</h3>
                </div>

                <div class="card-body">
                  Status: {{ credit.status.description }}
                </div>
              </div>
            </div>


            <div class="col-md-4" style="display: flex; flex-direction: column;">
              <div class="card card-outline card-primary" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                <div class="card-header">
                  <h3 class="card-title">Aktionen</h3>
                </div>

                <div class="card-body">
                  <button type="button" class="mr-1 btn btn-success" @click="downloadPdf"><i class="fas fa-download"></i> Download PDF</button>
                  <button v-if="credit.customer_id != null" type="button" class="btn btn-primary" @click="sendCredit"><i class="fas fa-envelope"></i> E-Mail senden</button>
                </div>
              </div>
            </div>

          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="card card-primary card-outline">
                <div class="card-header">
                  <h3 class="card-title">Positionen</h3>
                </div>

                <div class="card-body">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Anzahl</th>
                        <th>Preis</th>
                        <th>Steuer</th>
                        <th>Gesamt</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="item in credit.items" :key="item.id">
                        <td>{{ item.name }}</td>
                        <td>{{ item.quantity }}</td>
                        <td>{{ item.price | toCurrency }}</td>
                        <td>{{ item.tax_rate }} %</td>
                        <td>{{ item.price * item.quantity | toCurrency }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <b-modal :no-enforce-focus="true" id="mailOrderModal" title="E-Mail an den Kunden senden" ok-variant="primary" ok-title="E-Mail senden" cancel-title="Abbrechen" size="xl" @ok="sendNotification">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="">An:</label>
                        <input type="email" class="form-control" v-model="orderModal.email"/>
                    </div>

                    <div class="form-group">
                        <label for="">Betreff:</label>
                        <input type="text" class="form-control" v-model="orderModal.subject"/>
                    </div>

                    <!-- <div class="form-group">
                        <label for="">Anhänge:</label>
                        <select class="form-control" v-model="orderModal.attachment">
                            <option value=null>--Kein Anhang--</option>
                            <option v-for="document in order.documents" :key ="document.id" :value="document.id">{{ document.document.name + '-' + document.number }}</option>
                        </select>
                    </div> -->

                    <div class="form-group">
                        
                        <editor
                                v-bind:api-key="this.$tinyApiKey"
                                :init="{
                                    height: 400,
                                    menubar: true,
                                    plugins: [
                                    'code advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | removeformat | code | help'
                                }"
                                v-model="orderModal.message"
                              
                                />
                    </div>
                </div>
            </div>
        </b-modal>
  </b-container>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
// import ClassicEditor from '../../../assets/packages/ckeditor5/src/ckeditor';
import "tinymce/tinymce";
// import "./../../../assets/packages/tinymce-bootstrap";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/bootstrap";
import "tinymce/plugins/code";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/lists";
import "tinymce/plugins/charmap";
import "tinymce/plugins/print";
import "tinymce/plugins/preview";
import "tinymce/plugins/help";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/table";

export default {
  name: "CreditDetails",
  title: "Gutschrift",

  data(){
    return {
      editor: Editor,
      editorConfig: {
          simpleUpload: {
              uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
              withCredentials: true,
              headers: {
                  'Accept': 'application/json',
                  'Authorization': 'Bearer '+ this.$auth.token()
              }
          }
      },
      orderModal: {
                message: '',
                subject: '',
                email: '',
                company_id: '',
                attachment: null,
            },
      credit: {
        status:{
          description: "",
        }
      }
    }
  },

  methods: {
    sendNotification(bvModalEvt){
            bvModalEvt.preventDefault();
            this.$Progress.start();
            this.axios
            .post("/mail/send/credit", {
                'email': this.orderModal.email,
                'subject': this.orderModal.subject,
                'content': this.orderModal.message,
                'company_id': this.orderModal.company_id,
                'document_id': this.orderModal.attachment,
            })
            .then(() => {
            this.$Progress.finish();
            this.orderModal.attachment = null;
            this.$bvModal.hide('mailOrderModal');
            this.$swal({
                icon: "success",
                title: "E-Mail gesendet",
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                });
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
                this.$Progress.fail();
            });
        },

        sendCredit(){
            //console.log(status.name);
            this.$Progress.start();
            this.axios
            .post("/credits/mail/send", {
                'order_id': this.credit.id,
                'company_id': this.credit.company_id,
            })
            .then((response) => {
                this.$Progress.finish();
                this.orderModal.message = response.data.data.message;
                this.orderModal.subject = 'Deine Gutschrift im ' + this.credit.business.name;
                this.orderModal.email = this.credit.customer.email;
                this.orderModal.company_id = this.credit.company_id;
                this.orderModal.attachment = this.credit.id;
                this.$bvModal.show('mailOrderModal');
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
                this.$Progress.fail();
            });
        },


    downloadPdf(){
      this.axios
      .get("/credits/" + this.$route.params.id + "/download", {responseType: 'arraybuffer'})
      .then((response) => {
          //this.orders = response.data.data;
          this.downloadFile(response)
          this.$Progress.finish();
      })
      .catch(() => {
          this.$swal({
              icon: "error",
              title: "Oops...",
              text: "Da ist etwas schief gelaufen",
          });
          this.$Progress.fail();
      });
    },

    downloadFile(response){
        var newBlob = new Blob([response.data], {type: 'application/pdf'})

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob)
          return
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob)
        var link = document.createElement('a')
        link.href = data
        link.download = 'Gutschrift_' + this.credit.credit_number + '.pdf'
        link.click()
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data)
        }, 100)
    },

    getCredit(){
      this.$Progress.start();
      this.axios
          .get("/credits/" + this.$route.params.id)
          .then((response) => {
              this.credit = response.data.data;
              this.$Progress.finish();
          })
          .catch(() => {
              this.$swal({
                  icon: "error",
                  title: "Oops...",
                  text: "Da ist etwas schief gelaufen",
              });
              this.$Progress.fail();
          });
        }
    },

  mounted() {
    this.getCredit();
  }
}
</script>

<style>

</style>